
.page-not-found-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 95px);
    background-color: #f8f8f8;
  }

  .page-not-found-content-div {
    text-align: center;
  }

  .page-not-found-content-div h2 {
    font-size: 48px;
    font-weight: bold;
    margin-top: 0;
  }

  .page-not-found-content-div h4 {
    font-size: 24px;
    margin-top: 10px;
    color: #666;
  }

  .page-not-found-content-div a {
    color: #007bff;
    text-decoration: none;
  }

  .page-not-found-content-div a:hover {
    text-decoration: underline;
  }

  .fa-solid.fa-arrow-left,
  .fa-solid.fa-arrow-right {
    margin: 0 10px;
    font-size: 16px;
    vertical-align: middle;
  }

  @media (max-width: 768px) {
    .page-not-found-content-div h2 {
      font-size: 36px;
    }

    .page-not-found-content-div h4 {
      font-size: 18px;
    }
  }
