body {
  background-color: #fcfcfc;
  /* padding-bottom: 96px; */
  font-family:Arial, Helvetica, sans-serif;
  margin: 0 auto;
}

.landing-page-wrapper {
  min-height: calc(100vh - 95px);
}

.header-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  background-color: #FDEBD2;
}

.welcome {
  text-align: center;
}

.categories-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px 0;
}

a {
  text-decoration: none;
  color: black;
}

.category {
  text-decoration: none;
  margin: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px solid #ccc;
  border-radius: 30%;
  width: 120px;
  height: 120px;
  transition: all 0.2s ease-in-out;
  text-align: center;
}

.category:hover {
  border-color: #e84d4d;
  background-color: #FDEBD2;
  transform: scale(1.05);
}

.category h3 {
  font-size: 20px;
  color: #e84d4d;
  font-weight: bold;
}

.products-container {
  margin: 0 auto;
  padding: 0 20%;
  padding-bottom: 60px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 20px;
}

@media (max-width: 2000px) {
  .products-container {
    grid-template-columns: repeat(3, minmax(250px, 1fr));
  }
}

@media (max-width: 992px) {
  .products-container {
    /* Change grid-template-columns to have 3 columns */
    grid-template-columns: repeat(2, minmax(250px, 1fr));
    padding: 0;
  }
}

@media (max-width: 768px) {
  .products-container {
    /* Change grid-template-columns to have 2 columns */
    grid-template-columns: 1fr;
  }
}

/* @media (max-width: 576px) {
  .products-container {
    grid-template-columns: 1fr;
  }
} */

.shop-ours, .top-rated{
  padding: 0 20%;
  color: #e84d4d;
}

.product {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #f9f6f1;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease-in-out;
  border-radius: 10px;
}


.product:hover {
  box-shadow: 0 0 10px rgba(219, 16, 16, 0.766);
  transform: scale(1.05);
}

.product .image-container {
  position: relative;
}

.product img {
  width: 250px;
  height: 200px;
  object-fit: cover;
  /* margin-bottom: 10px; */
}

.product .price {
  position: absolute;
  top: 70%;
  left: 8px;
  background-color: rgba(249, 253, 251, 0.87);
  padding: 0.5rem;
  border-radius: 20px;
  font-weight: bold;
  font-size: 12px;
}

.product h4 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
  /* color: #e84d4d; */
}

.product p {
  font-size: 16px;
  margin-bottom: 10px;
}

.about-us {
  background-color: #FDEBD2;
  display: flex;
  flex-direction: column;
  padding: 10px 150px;
  align-items: center;
  overflow: auto;
}

.about-us h4 {
  margin-bottom: 0;
}

.about-us-p {
  margin-top: 5px;
  font-style: italic;
}

.introduction {
  display: flex;
  flex-direction: row;
}

.introduction p {
  text-align: justify;
  margin-right: 40px;
  width: 250px;
  font-style: italic;
  overflow: auto;
}

.blue-div {
  display: flex;
  justify-content: center;
  background-color: #4d6bc6;
  color: #fff;
}

/* .top-rated-products {
  display: flex;
  flex-direction: row;
  padding-bottom: 30px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.top-rated-products .product {
  width: 12%;
  height: 300px;
  margin: 10px;
  border-radius: 40px;
} */

/* .top-rated-wrapper {
  display: flex;
  gap: 20px;
} */

.top-rated-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  gap: 1rem;
  padding: 0 20%;
  margin: 0 auto;

}

.top-rated-wrapper .product {
  width: 250px;
  height: 300px;
}

@media (max-width: 768px) {
  .top-rated-wrapper {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .top-rated-products > div {
    width: 250px;
    margin-bottom: 1rem;
  }
}
