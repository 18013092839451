.shopping-cart-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    min-height: calc(100vh - 95px);
}

.shopping-cart-title {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 20px;
}

.cart-items-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.cart-item-container {
    flex-basis: 70%;
}

.cart-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
    border-bottom: 1px solid #ccc;
    padding-bottom: 20px;
}

.cart-item-details {
    display: flex;
    align-items: center;
}

.cart-item-image {
    width: 100px;
    height: 100px;
    margin-right: 20px;
}

.cart-item-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* .cart-item-info {
    flex-basis: 70%;
} */

.cart-item-name {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
    margin-top: 0;
}

.cart-item-price {
    font-size: 18px;
    font-weight: bold;
}

.cart-item-quantity {
    display: flex;
    align-items: center;
    margin-right: 5px;
}

.cart-item-quantity label {
    margin-right: 5px;
}

.cart-item-remove {
    background-color: #f15b5b;
    border: 1px solid #ccc;
    padding: 5px 10px;
    font-size: 16px;
    color: #fff;
    cursor: pointer;
    border-radius: 5px;
    margin-left: 5px;
}

.items-total-container {
    flex-basis: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.items-total {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
}

.pay-button {
    background-color: #eedabf;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border-radius: 5px;
}

.pay-button:hover {
    background-color: #fff;
    border: solid 2px #eedabf;
    color: #eedabf;
    border-radius: 5px;
}
