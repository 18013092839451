.complete-order-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    min-height: calc(100vh - 95px);
  }

  .complete-order-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .complete-order-image {
    width: 25rem;
    height: auto;
    margin: 20px;
  }

  .complete-order-text {
    font-size: 18px;
    margin-bottom: 10px;
  }

  .complete-order-note {
    font-size: 14px;
    margin-top: 20px;
    font-style:italic;
    font-weight: bold;
  }

  .complete-order-link{
    margin-top: 20px;
    text-decoration: none;
    color: #b9a890;
    font-weight: bold;
    border: 2px solid #b9a890;
    padding: 10px 20px;
    border-radius: 5px;
    transition: all 0.3s ease;
  }

  .complete-order-link:hover {
    text-decoration: none;
    background-color: #b9a890;
    color: #fff;
  }
